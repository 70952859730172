import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/body/dry-skin/body-dryskin-hero.webp";
import productInfoIcon from "images/icons/icon-dry-skin-condition.png";
import productInfoImg from "images/body/dry-skin/dry-skin-data-visual.webp";
import img1 from "images/callouts/cout-advanced-repair-lotion.webp";
import img2 from "images/callouts/cout-advanced-repair-cream.webp";
import img3 from "images/body/dry-skin/section-cout-aquaphor-spray.webp";
import img4 from "images/callouts/cout-footer-eczema.webp";
import img5 from "images/callouts/cout-footer-aquaphor.webp";
import img6 from "images/callouts/cout-footer-support-baby.webp";

import "./dryskin.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function DrySkinPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-body-dryskin"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Dry Skin"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic solutions for dry skin"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#6ACAD5"
            headText="DRY SKIN"
            infoText="Replenish what nature provided to treat dry skin"
            bodySpanText="The therapeutic goal of dry skin treatment&nbsp;"
            bodyText="is to moisturize and strengthen the skin’s natural, protective moisture barrier."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Icon for therapeutic goal of dry skin treatment"
            productInfoImg={productInfoImg}
            productInfoImgAlt="Dry Skin Data Visual Image"
          >
            <h5 className="northern-lights-blue">
              The Eucerin<sup>&reg;</sup> complete therapeutic approach
            </h5>
            <p>
              Eucerin<sup>&reg;</sup> Advanced Repair is formulated with natural
              moisturizing factors (NMFs), found in the epidermis, and ceramide
              NP to:
            </p>
            <ul className="teal-bullets">
              <li>
                <span>
                  <span className="font-bold">Increase</span> water binding in
                  the stratum corneum
                </span>
              </li>
              <li>
                <span>
                  <span className="font-bold">Reduce</span> water loss
                </span>
              </li>
              <li>
                <span>
                  <span className="font-bold">Improve</span> skin’s moisture
                  barrier
                </span>
              </li>
            </ul>
          </CategoryDetails>
        </section>
        <section className="callouts-container gradient-bg gray-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Advanced Repair Lotion`}
                  head1Css="northern-lights-blue"
                  buttonText="See product information"
                  buttonUrl="/body/dryskin/eucerinadvancedrepairlotion"
                  className="center-v"
                >
                  Helps strengthen skin’s moisture barrier and provides moisture
                  that lasts
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Eucerin Advanced Repair Lotion"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Advanced Repair Cream`}
                  head1Css="northern-lights-blue"
                  buttonText="See product information"
                  buttonUrl="/body/dryskin/eucerinadvancedrepaircream"
                  className="center-v"
                >
                  Helps treat the many causes of dry skin with daily,
                  long-lasting hydration
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Eucerin Advanced Repair Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg section-dry-rough-skin">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={12} lg={6} order={{ xs: 2, lg: 1 }} className="">
                <TypeC
                  prodLabel={`DRY, ROUGH SKIN`}
                  prodLabelCss="h3-boxed dark-blue"
                  buttonText="See product information"
                  buttonUrl="/body/dryskin/aquaphorbodyspray"
                  className="center-v"
                >
                  <p className="reg-text-big reg-text-big-only">
                    All-over relief for dry, rough skin in a convenient spray
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={12} lg={6} order={{ xs: 1, lg: 2 }} className="">
                <img
                  src={img3}
                  alt="Aquaphor Body Spray"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Eczema Regimen"
                  caption="Choose a proactive therapeutic regimen for eczema"
                  buttonText="Eczema Regimen"
                  buttonUrl="/body/eczema"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Dry, Damaged Skin"
                  caption="Take the Aquaphor<sup>®</sup> Approach to help heal dry, damaged skin"
                  buttonText="Dry, Damaged Skin"
                  buttonUrl="/body/damagedskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Browse Resources"
                  caption="Find resources to support your baby care recommendations"
                  buttonText="Browse Resources"
                  buttonUrl="/resources"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DrySkinPage;
